var exports = {};
!function (t, n) {
  exports = n();
}(exports, function () {
  "use strict";

  function t() {
    return "".concat(Math.random().toString(36), "00000000000000000").replace(/[^a-z]+/g, "").slice(0, 5);
  }

  function i(t, n) {
    (null == n || n > t.length) && (n = t.length);

    for (var c = 0, a = new Array(n); c < n; c++) a[c] = t[c];

    return a;
  }

  function d(t, n) {
    var c;

    if ("undefined" == typeof Symbol || null == t[Symbol.iterator]) {
      if (Array.isArray(t) || (c = function (t, n) {
        if (t) {
          if ("string" == typeof t) return i(t, n);
          var c = Object.prototype.toString.call(t).slice(8, -1);
          return "Object" === c && t.constructor && (c = t.constructor.name), "Map" === c || "Set" === c ? Array.from(t) : "Arguments" === c || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(c) ? i(t, n) : void 0;
        }
      }(t)) || n && t && "number" == typeof t.length) {
        c && (t = c);

        var a = 0,
            r = function () {};

        return {
          s: r,
          n: function () {
            return a >= t.length ? {
              done: !0
            } : {
              done: !1,
              value: t[a++]
            };
          },
          e: function (t) {
            throw t;
          },
          f: r
        };
      }

      throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
    }

    var o,
        e = !0,
        u = !1;
    return {
      s: function () {
        c = t[Symbol.iterator]();
      },
      n: function () {
        var t = c.next();
        return e = t.done, t;
      },
      e: function (t) {
        u = !0, o = t;
      },
      f: function () {
        try {
          e || null == c.return || c.return();
        } finally {
          if (u) throw o;
        }
      }
    };
  }

  return {
    circles: function () {
      function n(t) {
        var n = t.append("defs").append("pattern").attr("id", h).attr("patternUnits", "userSpaceOnUse").attr("width", o).attr("height", o);
        if (e && n.append("rect").attr("width", o).attr("height", o).attr("fill", e), n.append("circle").attr("cx", o / 2).attr("cy", o / 2).attr("r", u).attr("fill", l).attr("stroke", f).attr("stroke-width", s), i) for (var c = 0, a = [[0, 0], [0, o], [o, 0], [o, o]]; c < a.length; c++) {
          var r = a[c];
          n.append("circle").attr("cx", r[0]).attr("cy", r[1]).attr("r", u).attr("fill", l).attr("stroke", f).attr("stroke-width", s);
        }
      }

      var o = 20,
          e = "",
          u = 2,
          i = !1,
          l = "#343434",
          f = "#343434",
          s = 0,
          h = t();
      return n.heavier = function (t) {
        return u *= 0 === arguments.length ? 2 : 2 * t, n;
      }, n.lighter = function (t) {
        return u /= 0 === arguments.length ? 2 : 2 * t, n;
      }, n.thinner = function (t) {
        return o *= 0 === arguments.length ? 2 : 2 * t, n;
      }, n.thicker = function (t) {
        return o /= 0 === arguments.length ? 2 : 2 * t, n;
      }, n.background = function (t) {
        return e = t, n;
      }, n.size = function (t) {
        return o = t, n;
      }, n.complement = function (t) {
        return i = 0 === arguments.length || t, n;
      }, n.radius = function (t) {
        return u = t, n;
      }, n.fill = function (t) {
        return l = t, n;
      }, n.stroke = function (t) {
        return f = t, n;
      }, n.strokeWidth = function (t) {
        return s = t, n;
      }, n.id = function (t) {
        return 0 === arguments.length ? h : (h = t, n);
      }, n.url = function () {
        return "url(#".concat(h, ")");
      }, n;
    },
    lines: function () {
      function o(t) {
        var n = e;

        switch (t) {
          case "0/8":
          case "vertical":
            return "M ".concat(n / 2, ", 0 l 0, ").concat(n);

          case "1/8":
            return "M ".concat(-n / 4, ",").concat(n, " l ").concat(n / 2, ",").concat(-n, " M ").concat(n / 4, ",").concat(n, " l ").concat(n / 2, ",").concat(-n, " M ").concat(3 * n / 4, ",").concat(n, " l ").concat(n / 2, ",").concat(-n);

          case "2/8":
          case "diagonal":
            return "M 0,".concat(n, " l ").concat(n, ",").concat(-n, " M ").concat(-n / 4, ",").concat(n / 4, " l ").concat(n / 2, ",").concat(-n / 2, " M ").concat(0.75 * n, ",").concat(5 / 4 * n, " l ").concat(n / 2, ",").concat(-n / 2);

          case "3/8":
            return "M 0,".concat(0.75 * n, " l ").concat(n, ",").concat(-n / 2, " M 0,").concat(n / 4, " l ").concat(n, ",").concat(-n / 2, " M 0,").concat(5 * n / 4, " l ").concat(n, ",").concat(-n / 2);

          case "4/8":
          case "horizontal":
            return "M 0,".concat(n / 2, " l ").concat(n, ",0");

          case "5/8":
            return "M 0,".concat(-n / 4, " l ").concat(n, ",").concat(n / 2, "M 0,").concat(n / 4, " l ").concat(n, ",").concat(n / 2, " M 0,").concat(3 * n / 4, " l ").concat(n, ",").concat(n / 2);

          case "6/8":
            return "M 0,0 l ".concat(n, ",").concat(n, " M ").concat(-n / 4, ",").concat(0.75 * n, " l ").concat(n / 2, ",").concat(n / 2, " M ").concat(3 * n / 4, ",").concat(-n / 4, " l ").concat(n / 2, ",").concat(n / 2);

          case "7/8":
            return "M ".concat(-n / 4, ",0 l ").concat(n / 2, ",").concat(n, " M ").concat(n / 4, ",0 l ").concat(n / 2, ",").concat(n, " M ").concat(3 * n / 4, ",0 l ").concat(n / 2, ",").concat(n);

          default:
            return "M ".concat(n / 2, ", 0 l 0, ").concat(n);
        }
      }

      function a(t) {
        var n = t.append("defs").append("pattern").attr("id", f).attr("patternUnits", "userSpaceOnUse").attr("width", e).attr("height", e);
        l && n.append("rect").attr("width", e).attr("height", e).attr("fill", l);
        var c,
            a = d(s);

        try {
          for (a.s(); !(c = a.n()).done;) {
            var r = c.value;
            n.append("path").attr("d", o(r)).attr("stroke-width", i).attr("shape-rendering", h).attr("stroke", u).attr("stroke-linecap", "square");
          }
        } catch (t) {
          a.e(t);
        } finally {
          a.f();
        }
      }

      var e = 20,
          u = "#343434",
          i = 2,
          l = "",
          f = t(),
          s = ["diagonal"],
          h = "auto";
      return a.heavier = function (t) {
        return i *= 0 === arguments.length ? 2 : 2 * t, a;
      }, a.lighter = function (t) {
        return i /= 0 === arguments.length ? 2 : 2 * t, a;
      }, a.thinner = function (t) {
        return e *= 0 === arguments.length ? 2 : 2 * t, a;
      }, a.thicker = function (t) {
        return e /= 0 === arguments.length ? 2 : 2 * t, a;
      }, a.background = function (t) {
        return l = t, a;
      }, a.size = function (t) {
        return e = t, a;
      }, a.orientation = function () {
        for (var t = arguments.length, n = new Array(t), c = 0; c < t; c++) n[c] = arguments[c];

        return 0 === arguments.length || (s = n), a;
      }, a.shapeRendering = function (t) {
        return h = t, a;
      }, a.stroke = function (t) {
        return u = t, a;
      }, a.strokeWidth = function (t) {
        return i = t, a;
      }, a.id = function (t) {
        return 0 === arguments.length ? f : (f = t, a);
      }, a.url = function () {
        return "url(#".concat(f, ")");
      }, a;
    },
    paths: function () {
      function n(t) {
        var n = function (t) {
          var n = o;

          switch (t) {
            case "squares":
              return "M ".concat(n / 4, " ").concat(n / 4, " l ").concat(n / 2, " 0 l 0 ").concat(n / 2, " l ").concat(-n / 2, " 0 Z");

            case "nylon":
              return "M 0 ".concat(n / 4, " l ").concat(n / 4, " 0 l 0 ").concat(-n / 4, " M ").concat(3 * n / 4, " ").concat(n, " l 0 ").concat(-n / 4, " l ").concat(n / 4, " 0 M ").concat(n / 4, " ").concat(n / 2, " l 0 ").concat(n / 4, " l ").concat(n / 4, " 0 M ").concat(n / 2, " ").concat(n / 4, " l ").concat(n / 4, " 0 l 0 ").concat(n / 4);

            case "waves":
              return "M 0 ".concat(n / 2, " c ").concat(n / 8, " ").concat(-n / 4, " , ").concat(3 * n / 8, " ").concat(-n / 4, " , ").concat(n / 2, " 0 c ").concat(n / 8, " ").concat(n / 4, " , ").concat(3 * n / 8, " ").concat(n / 4, " , ").concat(n / 2, " 0 M ").concat(-n / 2, " ").concat(n / 2, " c ").concat(n / 8, " ").concat(n / 4, " , ").concat(3 * n / 8, " ").concat(n / 4, " , ").concat(n / 2, " 0 M ").concat(n, " ").concat(n / 2, " c ").concat(n / 8, " ").concat(-n / 4, " , ").concat(3 * n / 8, " ").concat(-n / 4, " , ").concat(n / 2, " 0");

            case "woven":
              return "M ".concat(n / 4, ",").concat(n / 4, "l").concat(n / 2, ",").concat(n / 2, "M").concat(3 * n / 4, ",").concat(n / 4, "l").concat(n / 2, ",").concat(-n / 2, " M").concat(n / 4, ",").concat(3 * n / 4, "l").concat(-n / 2, ",").concat(n / 2, "M").concat(3 * n / 4, ",").concat(5 * n / 4, "l").concat(n / 2, ",").concat(-n / 2, " M").concat(-n / 4, ",").concat(n / 4, "l").concat(n / 2, ",").concat(-n / 2);

            case "crosses":
              return "M ".concat(n / 4, ",").concat(n / 4, "l").concat(n / 2, ",").concat(n / 2, "M").concat(n / 4, ",").concat(3 * n / 4, "l").concat(n / 2, ",").concat(-n / 2);

            case "caps":
              return "M ".concat(n / 4, ",").concat(3 * n / 4, "l").concat(n / 4, ",").concat(-n / 2, "l").concat(n / 4, ",").concat(n / 2);

            case "hexagons":
              return a = 3, r = Math.sqrt(3), "M ".concat(n, ",0 l ").concat(n, ",0 l ").concat(n / 2, ",").concat(n * Math.sqrt(3) / 2, " l ").concat(-n / 2, ",").concat(n * Math.sqrt(3) / 2, " l ").concat(-n, ",0 l ").concat(-n / 2, ",").concat(-n * Math.sqrt(3) / 2, " Z M 0,").concat(n * Math.sqrt(3) / 2, " l ").concat(n / 2, ",0 M ").concat(3 * n, ",").concat(n * Math.sqrt(3) / 2, " l ").concat(-n / 2, ",0");

            default:
              return t(n);
          }
        }(l),
            c = t.append("defs").append("pattern").attr("id", f).attr("patternUnits", "userSpaceOnUse").attr("width", o * a).attr("height", o * r);

        i && c.append("rect").attr("width", o * a).attr("height", o * r).attr("fill", i), c.append("path").attr("d", n).attr("fill", s).attr("stroke", e).attr("stroke-width", u).attr("stroke-linecap", "square").attr("shape-rendering", h);
      }

      var a = 1,
          r = 1,
          o = 20,
          e = "#343434",
          u = 2,
          i = "",
          l = function (t) {
        return "M ".concat(t / 4, ",").concat(3 * t / 4, "l").concat(t / 4, ",").concat(-t / 2, "l").concat(t / 4, ",").concat(t / 2);
      },
          f = t(),
          s = "transparent",
          h = "auto";

      return n.heavier = function (t) {
        return u *= 0 === arguments.length ? 2 : 2 * t, n;
      }, n.lighter = function (t) {
        return u /= 0 === arguments.length ? 2 : 2 * t, n;
      }, n.thinner = function (t) {
        return o *= 0 === arguments.length ? 2 : 2 * t, n;
      }, n.thicker = function (t) {
        return o /= 0 === arguments.length ? 2 : 2 * t, n;
      }, n.background = function (t) {
        return i = t, n;
      }, n.shapeRendering = function (t) {
        return h = t, n;
      }, n.size = function (t) {
        return o = t, n;
      }, n.d = function (t) {
        return l = t, n;
      }, n.fill = function (t) {
        return s = t, n;
      }, n.stroke = function (t) {
        return e = t, n;
      }, n.strokeWidth = function (t) {
        return u = t, n;
      }, n.id = function (t) {
        return 0 === arguments.length ? f : (f = t, n);
      }, n.url = function () {
        return "url(#".concat(f, ")");
      }, n;
    }
  };
});
export default exports;